<template>
  <div class="detail">
    <div class="location">
      <i class="el-icon-place"></i>
      <el-breadcrumb
        class="el__breadcrumb"
        separator-class="el-icon-arrow-right"
      >
        <el-breadcrumb-item>您当前的位置</el-breadcrumb-item>
        <el-breadcrumb-item>新闻详情</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="content">
      <div class="left">
        <div class="head">
          <div class="title">
            {{ detail.title }}
          </div>
          <div class="sub">
            <span>发布日期：{{ detail.createTime }}</span>
            | <span>来源：{{ detail.sources }} </span>|
            <span> 新闻类型：{{ detail.categoryName }}</span>
          </div>
        </div>
        <div class="desc" v-html="detail.content"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { getNewsDetail } from "@/api/news.js";
import { htmlToText } from "@/utils/tools";

export default {
  name: "detail",
  computed: {
    id() {
      return this.$route.params.id;
    },
  },
  data() {
    return {
      detail: {},
    };
  },

  mounted() {
    this.getNewsDetail();
  },
  methods: {
    getNewsDetail() {
      getNewsDetail(this.id).then((res) => {
        this.detail = {
          ...res.data,
          // content: htmlToText(res.data.content),
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
pre {
  white-space: pre-wrap; /*css-3*/
  white-space: -moz-pre-wrap; /*Mozilla,since1999*/
  white-space: -pre-wrap; /*Opera4-6*/
  white-space: -o-pre-wrap; /*Opera7*/
  word-wrap: break-word; /*InternetExplorer5.5+*/
}
.detail {
  background: #ffffff;
  min-height: calc(100vh - 460px);
  .location {
    padding: 20px;
    display: flex;
    align-items: center;
    .el__breadcrumb {
      margin-left: 8px;
    }
  }
  .content {
    padding: 30px 50px 70px;
    display: flex;
    .left {
      width: 780px;
      display: flex;
      flex-direction: column;
      padding-right: 26px;
      .head {
        position: relative;
        border-bottom: 1px solid #eeeeee;
        padding-bottom: 20px;
        text-align: center;
        .title {
          font-size: 24px;
          font-weight: 700;
          color: #2a2b2e;
        }
        .status-img {
          position: absolute;
          width: 100px;
          top: 0;
          right: 0;
        }
        .sub {
          font-size: 14px;
          color: #999999;
          margin-top: 26px;
          span{
            margin: 0 10px;
          }
        }
      }
      .desc {
        font-size: 15px;
        color: #555555;
        line-height: 32px;
        margin-top: 14px;
        /deep/ img {
          width: 100%;
        }
      }
      .img-list {
        margin-top: 50px;
        display: flex;
        .img {
          width: 148px;
          height: 148px;
          border-radius: 5px;
          margin: 0 10px 10px 0;
          border: 1px dotted #eeeeee;
        }
      }
      .video-list {
        display: flex;
        .video {
          position: relative;
          width: 148px;
          height: 148px;
          border-radius: 5px;
          margin: 0 10px 10px 0;
          border: 1px dotted #eeeeee;
          overflow: hidden;
          .play-btn {
            position: absolute;
            margin: auto;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            // transform: translate(-50%, -50%);
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.2);
            cursor: pointer;
            transition: 0.5s;
            img {
              width: 50%;
              height: 50%;
            }
          }
          .play-btn:hover {
            transform: scale(1.5);
            transform-origin: center;
            background-color: rgba(0, 0, 0, 0.8);
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
      .like {
        width: 138px;
        height: 44px;
        border: 1px solid #ff281b;
        font-size: 20px;
        color: #ff281b;
        border-radius: 46px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 50px auto;
        cursor: pointer;
        img {
          width: 20px;
          margin-right: 8px;
        }
      }
      .reply {
        background: #f9fafc;
        padding: 60px 30px 24px 50px;
        position: relative;
        .pos_tag {
          position: absolute;
          top: -10px;
          left: 20px;
          width: 136px;
          height: 40px;
          background: url("~images/detail/bg.png") center center no-repeat;
          background-size: cover;
          font-size: 20px;
          color: #ffffff;
          text-align: center;
          line-height: 40px;
        }
        .name {
          font-size: 18px;
          font-weight: 700;
          color: #2a2b2e;
        }
        .txt {
          margin-top: 18px;
          line-height: 1.4;
        }
        .next {
          font-size: 13px;
          color: #90949e;
          margin-top: 15px;
          margin-bottom: 30px;
        }
      }
      .bottom {
        margin-top: 48px;
        .label {
          font-size: 18px;
          color: #2a2b2e;
          display: flex;
          align-items: center;
          font-weight: 700;
          &::before {
            content: "";
            display: block;
            width: 4px;
            height: 22px;
            margin-right: 10px;
            background: #5cbd31;
          }
        }
        .unlogin {
          margin-top: 22px;
          background: #fff9f9;
          border: 1px dashed #d03333;
          padding: 32px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .lt {
            font-size: 14px;
            color: #999999;
            span {
              color: #ff281b;
            }
          }
          .el-button {
            width: 108px;
            height: 38px;
            background: #d03333;
            border-radius: 2px;
            font-size: 14px;
            color: #ffffff;
            border: 0;
          }
        }
        .login {
          margin-top: 22px;
          display: flex;
          .el-textarea {
            width: 670px;
            /deep/ .el-textarea__inner {
              border: 1px solid #dcdfe6;
              border-radius: 0;
            }
          }
          .submit {
            flex: 1;
            background: #5cbd31;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 14px;
            color: #ffffff;
            cursor: pointer;
          }
        }
      }
      .comments {
        margin-top: 40px;
        .label {
          font-size: 18px;
          color: #2a2b2e;
          display: flex;
          align-items: center;
          font-weight: 700;
          &::before {
            content: "";
            display: block;
            width: 4px;
            height: 22px;
            margin-right: 10px;
            background: #5cbd31;
          }
        }
        ul {
          margin-top: 20px;
          li {
            display: flex;
            // align-items: center;
            padding: 20px 0;
            &:not(:last-child) {
              box-shadow: 0px -1px 0px 0px #eeeeee inset;
            }
            .avatar {
              width: 50px;
              height: 50px;
              // padding: 10px;
              background: #f9fafc;
              border-radius: 25px;
            }
            .main {
              flex: 1;
              padding-left: 20px;
              .top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                .lt {
                  display: flex;
                  align-items: center;
                  .name {
                    font-size: 13px;
                    color: #335db0;
                  }
                  .time,
                  .ip {
                    font-size: 13px;
                    color: #999999;
                    margin-left: 14px;
                  }
                }
                .rt {
                  display: flex;
                  align-items: center;
                  font-size: 13px;
                  color: #9c9da1;
                  img {
                    width: 14px;
                    margin-right: 4px;
                  }
                }
              }
              .txt {
                font-size: 14px;
                color: #4b4b4b;
                line-height: 24px;
                margin-top: 8px;
              }
            }
          }
        }
      }
    }
    .right {
      flex: 1;
      padding-left: 28px;
      box-shadow: 1px 0px 0px 0px #eeeeee inset;
      .feedbackName {
        color: #5cbd31;
      }
    }
  }
}
</style>

<style lang="scss">
.detail .content .left .reply .txt {
  img {
    max-width: 200px !important;
  }
}
</style>
